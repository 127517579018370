import Helper from "./helper";

class FormHelper {
  static nama() {
    return this.string('nama', 'Nama', [this.createRequiredValidationRule()])
  }

  static isAktif() {
    return this.checkbox('is_aktif', 'Aktif')
  }

  static tanggal(dataField = 'tanggal', label = 'Tanggal', validationRules = [this.createRequiredValidationRule()]) {
    return this.createField('simple', dataField, label, 'dxDateBox', this.createTanggalOptions(), validationRules)
  }

  static createField(itemType, dataField, label, editorType, editorOptions, validationRules) {
    let field = {
      itemType,
      dataField,
      editorType,
      editorOptions,
      label: {
        position: "top",
        text: label,
      },
      validationRules,
    };
    return field;
  }

  static noRef(field = 'no_ref', label = 'No Ref') {
    return this.string(field, label, [])
  }

  static keterangan() {
    return this.memo('keterangan', 'Keterangan', [])
  }

  static string(dataField, label, validationRules) {
    return {
      itemType: "simple",
      dataField,
      label: {
        position: "top",
        text: label,
      },
      validationRules,
    };
  }

  static password(dataField, label, validationRules) {
    return {
      itemType: "simple",
      editorType: 'dxTextBox',
      editorOptions: {
        mode: 'password'
      },
      dataField,
      label: {
        position: "top",
        text: label,
      },
      validationRules,
    };
  }

  static memo(dataField, label, validationRules) {
    return {
      itemType: 'simple',
      dataField,
      label: {
        position: "top",
        text: label,
      },
      editorType: 'dxTextArea',
      editorOptions: {
        height: 75,
      },
      validationRules,
    };
  }

  static tabbed(tabs, colSpan = 1) {
    return {
      itemType: "tabbed",
      tabs,
      colSpan,
    };
  }

  static tabItem(title, items, colCount = 1) {
    return {
      title,
      items,
      colCount,
    };
  }

  static date(dataField, label, validationRules) {
    return {
      itemType: "simple",
      dataField,
      label: {
        position: "top",
        text: label,
      },
      editorType: "dxDateBox",
      editorOptions: {
        displayFormat: "dd-MM-yyyy HH:mm:ss",
        useMaskBehavior: true,
      },
      validationRules,
    };
  }

  static lookup(dataField, label, url, validationRules) {
    return {
      itemType: "simple",
      dataField,
      label: {
        position: "top",
        text: label,
      },
      editorType: "dxSelectBox",
      editorOptions: {
        dataSource: Helper.createLookupDataSource(url),
        displayExpr: "nama",
        valueExpr: "id",
        searchEnabled: true,
        showClearButton: true,
      },
      validationRules,
    };
  }

  static number(dataField, label, validationRules) {
    return {
      itemType: "simple",
      dataField,
      label: {
        position: "top",
        text: label,
      },
      editorType: "dxNumberBox",
      editorOptions: {
        format: "#,##0.##;(#,##0.##)",
      },
      validationRules,
    };
  }

  static checkbox(dataField, label) {
    return {
      itemType: "simple",
      dataField,
      label: {
        visible: false,
      },
      editorType: "dxCheckBox",
      editorOptions: {
        text: label,
      },
    };
  }

  static createRequiredValidationRule(msg = "kolom ini harus diisi") {
    return {
      type: "required",
      message: msg,
    };
  }

  static createStringLengthRule(
    min = 1,
    max = 255,
    msg = "kolom ini melebihi batal"
  ) {
    return {
      type: "stringLength",
      min,
      max,
      message: msg,
    };
  }

  static button(text, icon, onClick, type = "success") {
    return {
      itemType: "button",
      buttonOptions: {
        text,
        icon,
        type,
        onClick,
      },
    };
  }

  static empty() {
    return {
      itemType: "empty",
    };
  }

  static createTanggalOptions() {
    return {
      displayFormat: "dd-MM-yyyy HH:mm:ss",
      type: "datetime",
      useMaskBehavior: true,
    }
  }

  static createNumberBoxOptions() {
    return {
      format: "#,##0.##;(#,##0.##)",
    }
  }

  static createTextBoxOptions() {
    return {
      maxLength: 255
    }
  }

  static createSelectBoxOptions(url, displayExpr = "nama", valueExpr = "id") {
    return {
      searchEnabled: true,
      showClearButton: true,
      dataSource: Helper.createLookupDataSource(url),
      displayExpr,
      valueExpr,
    }
  }

  static custom(templateName) {
    return {
      itemType: "simple",
      template: templateName,
    }
  }

  static createTextAreaOptions(height = 75) {
    return {
      height,
    }
  }
}

export default FormHelper;
