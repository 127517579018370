<template>
  <div>
    <dx-popup ref="dialog" :visible="visible" :title="title" :showCloseButton="true" :hideOnOutsideClick="false">
      <dx-toolbar-item toolbar="bottom" location="before" widget="dxButton" :options="batalOptions">
      </dx-toolbar-item>
      <dx-toolbar-item toolbar="bottom" location="after" widget="dxButton" :options="simpanOptions">
      </dx-toolbar-item>
      <dx-scroll-view height="100%" width="100%">
        <slot></slot>
      </dx-scroll-view>
    </dx-popup>
  </div>
</template>

<script>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';

export default {
  components: {
    DxPopup, DxToolbarItem,
    DxScrollView,
  },
  computed: {
    visible: {
      get() {
        return this.$attrs.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      }
    }
  },
  methods: {
  
  },
  props: {
    title: String,
  },
  emits: ['update:visible', 'simpan'],
  data() {
    return {
      batalOptions: {
        text: 'Batal',
        icon: 'fa-solid fa-times',
        onClick: () => {
          this.$emit('update:visible', false);
        }
      },
      simpanOptions: {
        text: 'Simpan',
        icon: 'fa-solid fa-save',
        type: 'success',
        onClick: () => {
          this.$emit('simpan');
        }
      }
    }
  }
}
</script>