<template>
  <div class="tw-text-2xl tw-mb-3">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'My Title'
    }
  }
}
</script>