import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp } from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";
import './main.css'

import App from "./App";
import appInfo from "./app-info";

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

import MyGrid from './components/my-grid.vue';
import MyTitle from './components/my-title.vue';
import MySpacer from './components/my-spacer.vue';

import MyEditDialog from './components/my-edit-dialog.vue';
import MyForm from './components/my-form.vue';
import MyLoadPanel from './components/my-load-panel.vue';

import 'animate.css';
import MySpacerLg from './components/my-spacer-lg.vue';
import { createPinia } from 'pinia';

themes.initialized(() => {
    config({ licenseKey });
    const app = createApp(App);
    const store = createPinia();
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.use(store);

    app.component('MyGrid', MyGrid);
    app.component('MyLoadPanel', MyLoadPanel);
    app.component('MyTitle', MyTitle);
    app.component('MySpacer', MySpacer);
    app.component('MySpacerLg', MySpacerLg);
    app.component('MyEditDialog', MyEditDialog);
    app.component('MyForm', MyForm);
    app.mount('#app');
});
