<template>
  <div>
    <dx-data-grid ref="grid" :dataSource="dataSource" :allow-column-reordering="true" @exporting="gridExporting"
      :column-min-width="120" column-resizing-mode="widget" :allow-column-resizing="true" :column-auto-width="true" height="100%" width="100%">
      <dx-remote-operations v-if="remoteOperations" :group-paging="false" :grouping="false"></dx-remote-operations>
      <dx-header-filter :visible="true">
        <dx-search :enabled="true" mode="contains"></dx-search>
      </dx-header-filter>
      <dx-grouping :context-menu-enabled="true"></dx-grouping>
      <dx-group-panel :visible="true"></dx-group-panel>
      <dx-search-panel :visible="true" width="240"></dx-search-panel>
      <dx-scrolling :scroll-by-content="true" mode="virtual"></dx-scrolling>
      <dx-filter-panel :visible="true"></dx-filter-panel>
      <dx-filter-row :visible="true"></dx-filter-row>
      <dx-column-chooser :enabled="true"></dx-column-chooser>
      <dx-state-storing :enabled="true" :storage-key="storageKey"></dx-state-storing>
      <dx-editing :allow-adding="bolehBaru" :allow-updating="bolehUpdate" :allow-deleting="bolehHapus" :use-icons="true"></dx-editing>
      <dx-export :enabled="bolehExport" :formats="gridExportFormats"></dx-export>
      <dx-toolbar>
        <dx-item name="groupPanel"></dx-item>
        <dx-item name="searchPanel"></dx-item>
        <dx-item v-if="bolehExport" name="exportButton"></dx-item>
        <dx-item name="columnChooserButton"></dx-item>
        <dx-item location="after" widget="dxButton" :options="{ icon: 'refresh', onClick: onRefresh }">
        </dx-item>
        <dx-item v-if="bolehBaru" location="after" widget="dxButton"
          :options="{ icon: 'add', onClick: onBaru }"></dx-item>
      </dx-toolbar>
      <dx-column type="buttons" :fixed="true" fixedPosition="left">
        <dx-button v-if="bolehUpdate" icon="edit" @click="onEdit"></dx-button>
        <dx-button v-if="bolehHapus" icon="trash" @click="onHapus"></dx-button>
        <dx-button v-if="bolehPrint" icon="print" @click="onPrint"></dx-button>
      </dx-column>

      <dx-column v-for="(item, index) in columns" :key="index" :data-field="item.dataField" :caption="item.caption"
        :data-type="item.dataType" :format="getColumnFormat(item)"></dx-column>
      <dx-summary>
        <dx-total-item v-for="(item, index) in totalItems" :key="index" :summary-type="item.summaryType"
          :column="item.column" display-format="{0}" :value-format="item.valueFormat"></dx-total-item>
      </dx-summary>
    </dx-data-grid>
  </div>
</template>

<script>

import GridHelper from '@/helper/grid-helper';
import { DxDataGrid, DxSearch, DxToolbar, DxItem, DxButton, DxColumn, DxSummary, DxTotalItem, DxColumnChooser, DxStateStoring, DxFilterRow, DxExport, DxEditing, DxScrolling, DxFilterPanel, DxGroupPanel, DxGrouping, DxSearchPanel, DxHeaderFilter, DxRemoteOperations } from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid, DxSearch, DxToolbar, DxItem, DxButton, DxColumn, DxSummary, DxTotalItem, DxColumnChooser, DxStateStoring, DxFilterRow, DxExport, DxEditing, DxScrolling,
    DxFilterPanel, DxGroupPanel, DxGrouping, DxSearchPanel, DxHeaderFilter, DxRemoteOperations
  },
  methods: {
    onPrint(e) {
      this.$emit('print', e);
    },
    gridExporting: GridHelper.onExporting,
    getColumnFormat(col) {
      switch (col.dataType) {
        case 'date':
          return 'dd-MM-yyyy HH:mm:ss';
        case 'number':
          return '#,##0.##;(#,##0.##)';
        default:
          return '';
      }
    }
  },
  props: {
    totalItems: {
      type: Array,
      default: () => []
    },
    remoteOperations: {
      type: Boolean,
      default: false
    },
    storageKey: {
      type: String,
      default: 'grid'
    },
    gridExportFormats: {
      type: Array,
      default: () => ['xlsx', 'csv', 'pdf']
    },
    onBaru: {
      type: Function,
      default: () => { }
    },
    onEdit: {
      type: Function,
      default: () => { }
    },
    onHapus: {
      type: Function,
      default: () => { }
    },
    onRefresh: {
      type: Function,
      default: () => { }
    },
    bolehBaru: {
      type: Boolean,
      default: true
    },
    bolehUpdate: {
      type: Boolean,
      default: true
    },
    bolehHapus: {
      type: Boolean,
      default: true
    },
    bolehExport: {
      type: Boolean,
      default: true
    },
    bolehPrint: {
      type: Boolean,
      default: false
    },
    daftar: {
      type: Object || Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  emits: ['print'],
  computed: {
    instance() {
      return this.$refs.grid.instance;
    },
    dataSource: {
      get() {
        return this.daftar;
      },
      set(value) {
        this.$emit('update:daftar', value);
      }
    }
  }
}
</script>