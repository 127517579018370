<template>
  <div>
    <dx-form ref="form" v-model:form-data="formDataComputed" :items="items">
      <slot></slot>
    </dx-form>
  </div>
</template>

<script>
import { DxForm } from 'devextreme-vue/form';
export default {
  components: {
    DxForm
  },
  computed: {
    formDataComputed: {
      get() {
        return this.formData;
      },
      set(value) {
        this.$emit('update:formData', value);
      }
    },
    instance() {
      return this.$refs.form.instance
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {

    }
  }
}
</script>