import { exportDataGrid as exportExcel } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { exportDataGrid as exportPdf } from "devextreme/pdf_exporter";
import { jsPDF } from 'jspdf';
import saveAs from "file-saver";
import Helper from "./helper";
import FormHelper from "./form-helper";
import { useAppStore } from "@/stores/app-store";

class GridHelper {
  static summary(column, summaryType) {
    return {
      column,
      summaryType,
      displayFormat: '{0}',
      valueFormat: '#,##0.##;(#,##0.##)'
    }
  }
  static number(dataField, caption) {
    return this.createColumn(dataField, caption, 'number', 'dxNumberBox', FormHelper.createNumberBoxOptions())
  }

  static button(icon, text, onClick) {
    return {
      icon,
      text,
      onClick
    }
  }

  static string(dataField, caption, validationRules = [], visible = true) {
    return {
      dataField,
      dataType: "string",
      caption,
      validationRules,
      visible
    };
  }

  static tanggal(dataField = 'tanggal', caption = 'Tanggal') {
    return this.createColumn(dataField, caption, 'date', 'dxDateBox', FormHelper.createTanggalOptions())
  }

  static no_dokumen() {
    return this.createColumn('no_dokumen', '#Dokumen', 'string')
  }

  static no_ref(visible = false) {
    return { ...this.createColumn('no_ref', 'No Ref', 'string'), visible }
  }

  static keterangan(visible = false) {
    return { ...this.createColumn('keterangan', 'Keterangan', 'string'), visible }
  }

  static noRefKeterangan(visible = false) {
    return [
      this.no_ref(visible),
      this.keterangan(visible)
    ]
  }

  static isAktif(dataField = 'is_aktif', caption = 'Is Aktif') {
    return {
      dataField,
      caption,
      dataType: "boolean",
    };
  }

  static bool(dataField, caption) {
    return {
      dataField,
      caption,
      dataType: "boolean",
    };
  }

  static createdAt(visible = false) {
    return {
      dataField: "created_at",
      dataType: "date",
      caption: "Created",
      format: "yyyy-MM-dd HH:mm:ss",
      visible
    };
  }

  static updatedAt() {
    return {
      dataField: "updated_at",
      dataType: "date",
      caption: "Updated",
      format: "yyyy-MM-dd HH:mm:ss",
    };
  }

  static userAdd(visible = false) {
    return {
      dataField: "user_add",
      dataType: "string",
      caption: "User Add",
      visible
    };
  }

  static userUpdate() {
    return {
      dataField: "user_update",
      dataType: "string",
      caption: "User Update",
    };
  }

  static lookup(dataField, calculateDisplayValue, caption, url, validationRules = [], displayExpr = 'nama', valueExpr = 'id') {
    return {
      dataField,
      caption,
      calculateDisplayValue,
      lookup: {
        dataSource: Helper.createCustomStoreConfig(url),
        valueExpr,
        displayExpr,
      },
      validationRules
    };
  }

  static toolbarItem(widget, location, options) {
    return {
      widget,
      location,
      options,
    };
  }

  static buttonColumn(fixed, fixedPosition, buttons) {
    return {
      type: "buttons",
      fixed,
      fixedPosition,
      buttons,
    };
  }

  static onExporting(e, fileName) {
    if (e.format == "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");

      exportExcel({
        component: e.component,
        worksheet: worksheet,
      })
        .then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              fileName + ".xlsx"
            );
          });
        })
        .then(function () {
          e.component.endUpdate();
        });
    } else if (e.format == "pdf") {
      const doc = new jsPDF();
      exportPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save(fileName + ".pdf");
      });
    }
  }

  static createSatuanLookup() {
    const store = useAppStore()
    let dataSource = (options) => {
      return {
        load() {
          return new Promise((resolve) => {
            store.post('helper/satuan_barang', { barang_id: options.data.barang_id })
              .then(daf => {
                resolve(daf)
              })
          })
        },
        byKey(satuan) {
          return new Promise((resolve) => {
            store.post('helper/satuan_barang', { barang_id: options.data.barang_id, satuan })
              .then(daf => {
                resolve(daf)
              })
          })
        }
      }
    }
    return {
      dataSource,
      displayExpr: 'satuan',
      valueExpr: 'satuan',
    }
  }

  static createColumn(dataField, caption, dataType = 'string', editorType = 'dxTextBox', editorOptions = {}, validationRules = []) {
    let format = null
    if (dataType == 'date') {
      format = 'dd-MM-yyyy HH:mm:ss'
    } else if (dataType == 'number') {
      format = '#,##0.##;(#,##0.##)'
    }
    return {
      dataField,
      caption,
      dataType,
      format,
      editorType,
      editorOptions,
      validationRules
    }
  }

  static createDefaultUserAndTimestamp() {
    return [
      this.createColumn('user_add', 'User Add', 'string'),
      this.createdAt(),
      this.createColumn('user_update', 'User Update', 'string'),
      this.updatedAt(),
    ]
  }

  static total_item(column, summaryType = 'sum', valueFormat = '#,##0.##;(#,##0.##)') {
    return {
      column,
      summaryType,
      displayFormat: '{0}',
      valueFormat
    }
  }
}

export default GridHelper;
