import notify from "devextreme/ui/notify";
import { confirm, alert } from "devextreme/ui/dialog";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { formatNumber as dxFormatNumber } from "devextreme/localization";
import { DateTime } from "luxon";
import Guid from "devextreme/core/guid"
import { useAppStore } from "@/stores/app-store";

class Helper {
  static toast(msg = '<i>Apakah Anda yakin?</i>', type = "info") {
    notify(msg, type);
  }

  static info(msgHtml, title = "Informasi") {
    alert(msgHtml, title);
  }

  static error(msgHtml, title = "Error") {
    alert(msgHtml, title);
  }
  
  static getLocalSetting() {
    let setting = localStorage.getItem('local_setting')
    if (setting) {
      return JSON.parse(setting)
    }
    return {}
  }

  static konfir(msgHtml = "<i>Apakah Anda yakin?</i>", title = "Konfirmasi") {
    return confirm(msgHtml, title);
  }

  static createGridDataSource(path) {
    return new DataSource(this.createCustomStoreConfig(path));
  }

  static createCustomStoreConfig(path) {
    return {
      key: "id",
      load(loadOptions) {
        return new Promise((resolve, reject) => {
          const store = useAppStore();
          store.post(path, loadOptions)
            .then((data) => {
              resolve(data);
            })
            .catch((e) => reject(e));
        });
      },
      byKey(key) {
        return new Promise((resolve, reject) => {
          const store = useAppStore();
          store.post(path, { id: key })
            .then((data) => {
              resolve(data);
            })
            .catch((e) => reject(e));
        });
      },
    };
  }

  static createLookupDataSource(path) {
    let store = new CustomStore(this.createCustomStoreConfig(path));
    return new DataSource({
      store,
      paginate: true,
      pageSize: 20,
    });
  }

  static readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  static setDaftarAkses(daftar_akses, konfigurasi) {
    if (!konfigurasi) {
      return
    }
    for (var k of konfigurasi) {
      let temp = k.split(":");
      let key = temp[0];
      let akses = temp[1];
      let itm = daftar_akses.find((x) => x.key === key);
      if (itm) {
        itm[akses] = true;
      }
    }
  }

  static formatNumber(n) {
    n = parseFloat(n)
    if (isNaN(n)) {
      return ''
    }
    return dxFormatNumber(n, '#,##0.##;(#,##0.##)')
  }

  static formatDateForApi(d, format = 'yyyy-MM-dd HH:mm:ss') {
    if (!d) {
      return ''
    }
    let t = null
    if (d instanceof Date) {
      t = DateTime.fromJSDate(d)
    } else {
      d = d.replace(/\//g, '-')
      t = DateTime.fromFormat(d, format)
    }
    return t.toFormat('yyyy-MM-dd HH:mm:ss')
    // return t.toISODate()
  }

  static uuid(length = 8) {
    // Generate a new UUID
    var newUUID = new Guid().toString();

    // Use the new UUID as a string
    return newUUID.replace(/-/g, '').substring(0, length)
  }

  static parseFloat(n) {
    let s = parseFloat(n)
    if (isNaN(n)) {
      return null
    }
    return s
  }

  static createLookupSatuan() {
    return {
      dataSource: (options) => {
        return {
          key: 'satuan',
          load: () => {
            return new Promise((resolve, reject) => {
              const store = useAppStore();
              store.post('helper/satuan_barang', { barang_id: options.data.barang_id })
                .then(data => {
                  resolve(data)
                })
                .catch(e => reject(e))
            })
          },
          byKey(satuan) {
            return new Promise((resolve, reject) => {
              const store = useAppStore();
              store.post('helper/satuan_barang', { barang_id: options.data.barang_id, satuan })
                .then(data => {
                  resolve(data)
                })
                .catch(e => reject(e))
            })
          }
        }
      },
      displayExpr: 'satuan',
      valueExpr: 'satuan',
    }
  }

  static terbilang(n) {
    const units = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan"];
    const teens = ["sepuluh", "sebelas"];
    const tens = ["", "", "dua puluh", "tiga puluh", "empat puluh", "lima puluh", "enam puluh", "tujuh puluh", "delapan puluh", "sembilan puluh"];
    const thousands = ["", "ribu", "juta", "miliar", "triliun"];

    if (n < 12) {
      return units[n];
    } else if (n < 20) {
      return teens[n - 10];
    } else if (n < 100) {
      return tens[Math.floor(n / 10)] + (n % 10 !== 0 ? " " + units[n % 10] : "");
    } else if (n < 200) {
      return "seratus" + (n % 100 !== 0 ? " " + this.terbilang(n % 100) : "");
    } else if (n < 1000) {
      return units[Math.floor(n / 100)] + " ratus" + (n % 100 !== 0 ? " " + this.terbilang(n % 100) : "");
    } else {
      let result = "";
      let i = 0;
      while (n > 0) {
        let remainder = n % 1000;
        if (remainder !== 0) {
          result = this.terbilang(remainder) + " " + thousands[i] + (result !== "" ? " " + result : "");
        }
        n = Math.floor(n / 1000);
        i++;
      }
      return result.trim();
    }
  }

  static startOfDay(d) {
    return DateTime.fromJSDate(d).startOf('day').toJSDate()
  }

  static endOfDay(d) {
    return DateTime.fromJSDate(d).endOf('day').toJSDate()
  }

 
}

export default Helper;
